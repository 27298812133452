import { useTranslation } from "react-i18next"
import validateForm from "../validation";
import { useRef, useState } from "react";
import jobs from "../data/openedPositionsData.json"
import { useParams } from "react-router-dom";

const JobApplyForm = () => {
  const { id } = useParams();
  const selectedJob = jobs.find(item => item.id == id);
  const { t } = useTranslation();
  const formRef = useRef();
  const fileRef = useRef();
  const [formData, setFormData] = useState({
    to: "appsteroffice@appstersolutions.com"
  });
  const [formSubmited, setFormSubmited] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null)

  const sendMessage = (event) => {
    if (!validateForm(formRef.current)) return;
    const _formData = new FormData();
    for (var i in formData) {
      _formData.append(i, formData[i]);
    }
    _formData.append('subject', `Prijava za poziciju ${selectedJob.title}`);
    _formData.append('attachment', uploadedFile);
    fetch('https://contactform.appsterdevelopment.co.rs/mail_cv.php', { method: 'POST', body: _formData }).then(res => res.text());
    setFormSubmited(true);
  };

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const uploadFile = (ref) => {
    setUploadedFile(ref.current.files[0])
  }

  return (
    <div className="contact-form bg-white p-80 rp-10 wow fadeInRight delay-0-2s">
      {!formSubmited && (
        <div
          onSubmit={(e) => e.preventDefault()}
          className="form-style-one"
          action="#"
          name="contact-form"
          ref={formRef}
        >
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="form-control"
                  defaultValue=""
                  placeholder={`*${t('full_name')}`}
                  required
                  onChange={changeHandler}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <input
                  type="text"
                  id="phone"
                  name="phoneNumber"
                  className="form-control"
                  defaultValue=""
                  placeholder={`*${t('phone_number')}`}
                  required
                  onChange={changeHandler}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <input
                  type="email"
                  id="emailid"
                  name="email"
                  className="form-control"
                  defaultValue=""
                  placeholder={`*${t('email')}`}
                  required
                  onChange={changeHandler}
                />
              </div>
            </div>
            <div className="col-md-12">
              <label>{t('upload_cv')}</label>
              <div className="form-group">
                <input
                  type="file"
                  id="cv"
                  name="cv"
                  className="form-control"
                  defaultValue=""
                  required
                  accept=".pdf, .doc, .docx"
                  ref={fileRef}
                  onInput={() => uploadFile(fileRef)}
                  onClick={(event) => { event.target.value = null }}
                />
              </div>
            </div>
            <div className="col-xl-12">
              <div className="form-group mb-0 text-center">
                <button type="submit" className="theme-btn style-two mt-15" onClick={sendMessage}>{t('send_cv')}</button>
              </div>
            </div>
          </div>
        </div>
      )}
      {formSubmited && (
        <h5 className="w-100 my-5 text-black" >{t('mail_sent')}</h5>
      )}
    </div>
  )
}
export default JobApplyForm